import $ from "jquery";
import { FooterHtmlService } from "../Services/Footer-Html-Service";

import "./Footer.less";

export class Footer {
    constructor() {
        const footerHtml: string = FooterHtmlService.getFooterHtml();
        this.setContent(footerHtml);
    }

    private setContent(footerHtml: string): void {
        $(".smb-footer").html(footerHtml);
    }
}