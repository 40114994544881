import $ from "jquery";
import Swiper, { Autoplay, Navigation, Pagination } from "swiper";
import { App } from "../App";

import "./Startseite.less";

export class Startseite {
    private readonly aktionenSwiper: Swiper;
    private readonly app: App;

    constructor() {
        this.app = new App();

        this.handleLocalStorage();
        this.bindEvents();

        $(".smb-startseite").toggleClass("smb-hide", false);

        const actionSlides: JQuery<Element> = $(".smb-startseite .smb-content-aktionen .swiper-container").find(".swiper-slide");

        Swiper.use([Navigation, Pagination, Autoplay]);

        this.aktionenSwiper = new Swiper(".smb-startseite .smb-content-aktionen .swiper-container", {
            autoplay: {
                delay: 300000,
                disableOnInteraction: true,
            },
            effect: "slide",
            loop: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                bulletActiveClass: "smb-is-active",
                bulletClass: "smb-bullet-container",
                bulletElement: "div",
                clickable: true,
                el: ".swiper-pagination",
                renderBullet(index: number, className: string) {
                    const bulletText: string = $(actionSlides[index]).attr("data-bullet-text") as string;

                    if (index === (actionSlides.length - 1)) {
                        return `<div class="${className}">
                                    <div class="smb-bullet">
                                        <div class="smb-swiper-bullet"></div>
                                        <div class="smb-bullet-text">${bulletText}</div>
                                    </div>
                                </div>
                                <div class="button-container">
                                    <div class="play smb-hide"></div>
                                    <div class="pause"></div>
                                </div>`;
                    }

                    return `<div class="${className}">
                                <div class="smb-bullet">
                                    <div class="smb-swiper-bullet"></div>
                                    <div class="smb-bullet-text">${bulletText}</div>
                                </div>
                                <div class="smb-bullte-connection"></div>
                            </div>`;
                },
                type: "bullets",
            },
            speed: 300,
        });
    }

    private bindEvents() {
        $(".smb-startseite .smb-content-start .smb-play-button").on("click", (event: JQuery.TriggeredEvent): void => {
            const backgroundVideoContainer: JQuery<Element> =
                $(".smb-startseite .smb-content-start .smb-background-video-conatiner");
            const playVideoContainer: JQuery<Element> =
                $(".smb-startseite .smb-content-start .smb-play-video-conatiner");

            let videoElement: HTMLVideoElement;

            if (this.isMobile()) {
                videoElement = playVideoContainer.find(".smb-start-play-video-mobile")[0] as HTMLVideoElement;
                this.app.menu.setBackground(false);
                $(".smb-header").toggleClass("smb-video-is-playing", true);
            }
            else {
                videoElement = playVideoContainer.find(".smb-start-play-video")[0] as HTMLVideoElement;
            }

            videoElement.load();
            videoElement.play()
                .then(() => {
                    // tu nix
                }).catch(() => {
                    // tu nix
                });

            backgroundVideoContainer.toggleClass("smb-hide", true);
            playVideoContainer.toggleClass("smb-hide", false);

            // $(".smb-start-play-video").show();
            // video.requestFullscreen();
            // video.play();
        });

        $(".smb-startseite .smb-content-start .smb-close-button").on("click", (event: JQuery.TriggeredEvent): void => {
            const backgroundVideoContainer: JQuery<Element> =
                $(".smb-startseite .smb-content-start .smb-background-video-conatiner");
            const playVideoContainer: JQuery<Element> =
                $(".smb-startseite .smb-content-start .smb-play-video-conatiner");

            backgroundVideoContainer.toggleClass("smb-hide", false);
            playVideoContainer.toggleClass("smb-hide", true);

            let videoElement: HTMLVideoElement;

            if (this.isMobile()) {
                videoElement = playVideoContainer.find(".smb-start-play-video-mobile")[0] as HTMLVideoElement;
                this.app.menu.setBackground(true);
                $(".smb-header").toggleClass("smb-video-is-playing", false);
            }
            else {
                videoElement = playVideoContainer.find(".smb-start-play-video")[0] as HTMLVideoElement;
            }

            videoElement.pause();
        });

        $(".smb-startseite .smb-content-facts-cards :not(.smb-id-tag-card) .smb-card-back").on("click",
            (event: JQuery.TriggeredEvent): void => {
                $(event.currentTarget).closest(".smb-card-content").toggleClass("smb-is-opened", true);
            });

        $(".smb-startseite .smb-content-facts-cards .smb-id-tag-card")
            .on("mouseenter", (event: JQuery.MouseEnterEvent) => {
                $("#smb-facts-card-id-tag-img")
                    .prop("src", "/Src/Images/Startseite/smb-startseite-facts-cards-id-tag-open.gif");
            })
            .on("mouseleave", (event: JQuery.MouseLeaveEvent) => {
                $("#smb-facts-card-id-tag-img")
                    .prop("src", "/Src/Images/Startseite/smb-startseite-facts-cards-id-tag-close.gif");
            });

        $(".smb-startseite .smb-content-products .smb-bullet").on("click", (event: JQuery.TriggeredEvent): void => {
            const currentBullet: JQuery<Element> = $(event.currentTarget);
            const allBullets: JQuery<Element> = $(".smb-startseite .smb-content-products .smb-bullet");

            const currentBulletContainer: JQuery<Element> = currentBullet.closest(".smb-bullets-conatiner");
            const allBulletsContainer: JQuery<Element> = $(".smb-startseite .smb-content-products .smb-bullets-conatiner");

            allBullets.toggleClass("smb-is-active", false);
            currentBullet.toggleClass("smb-is-active", true);

            allBulletsContainer.toggleClass("smb-is-active", false);
            currentBulletContainer.toggleClass("smb-is-active", true);

            const activeProduct: string = currentBullet.attr("data-active-product") as string;
            const productLivestyleImage: JQuery<Element> = $(".smb-startseite .smb-content-products .smb-image");

            $(".smb-startseite .smb-content-products .smb-text").toggleClass("smb-hide", true);

            let textContainer: JQuery<Element>;

            switch (activeProduct) {
                case "id-tag-black-gold":
                    productLivestyleImage.attr("data-image", "id-tag-black-gold");
                    currentBulletContainer.find(".smb-bullets-sub-title").html("BLACK-GOLD");

                    textContainer = $(`.smb-text[data-product="id-tag"]`);
                    textContainer.toggleClass("smb-hide", false);
                    textContainer.find(".smb-product-image").attr("data-image", "id-tag-black-gold");

                    break;
                case "id-tag-white-silver":
                    productLivestyleImage.attr("data-image", "id-tag-white-silver");
                    currentBulletContainer.find(".smb-bullets-sub-title").html("WHITE-SILVER");

                    textContainer = $(`.smb-text[data-product="id-tag"]`);
                    textContainer.toggleClass("smb-hide", false);
                    textContainer.find(".smb-product-image").attr("data-image", "id-tag-white-silver");

                    break;
                case "keykeeper-black":
                    productLivestyleImage.attr("data-image", "keykeeper-black");
                    currentBulletContainer.find(".smb-bullets-sub-title").html("FULL BLACK");

                    textContainer = $(`.smb-text[data-product="keykeeper"]`);
                    textContainer.toggleClass("smb-hide", false);
                    textContainer.find(".smb-product-image").attr("data-image", "keykeeper-black");

                    break;
                case "keykeeper-rosa":
                    productLivestyleImage.attr("data-image", "keykeeper-rosa");
                    currentBulletContainer.find(".smb-bullets-sub-title").html("SOFT ROSA");

                    textContainer = $(`.smb-text[data-product="keykeeper"]`);
                    textContainer.toggleClass("smb-hide", false);
                    textContainer.find(".smb-product-image").attr("data-image", "keykeeper-rosa");
                    break;
            }
        });

        $(".smb-startseite .smb-content-birds [data-bird]").on("click", (event: JQuery.TriggeredEvent): void => {
            const currentBirdElement: JQuery<Element> = $(event.currentTarget);
            const birdAttribute: string = currentBirdElement.attr("data-bird") as string;

            $(".smb-startseite .smb-content-birds [data-bird]").toggleClass("smb-is-active", false);
            currentBirdElement.toggleClass("smb-is-active", true);

            $(`.smb-startseite .smb-content-birds [data-bird-text]`).toggleClass("smb-hide", true);
            $(`.smb-startseite .smb-content-birds [data-bird-text="${birdAttribute}"]`).toggleClass("smb-hide", false);
            $(`.smb-startseite .smb-content-birds`).toggleClass("smb-bird-beprotect", false);
            $(`.smb-startseite .smb-content-birds`).toggleClass("smb-bird-keykeeper", false);
            $(`.smb-startseite .smb-content-birds`).toggleClass("smb-bird-sendmeback", false);
            $(`.smb-startseite .smb-content-birds`).toggleClass("smb-bird-kidsfoundation", false);
            $(`.smb-startseite .smb-content-birds`).toggleClass("smb-bird-rebirdished", false);
            $(`.smb-startseite .smb-content-birds`).toggleClass(`smb-bird-${birdAttribute}`, true);
        });

        $(".smb-startseite .smb-content-aktionen .smb-intro-swiper-container .button-container").on("click",
            (event: JQuery.TriggeredEvent) => {
                this.handleSwiperAutoPlay(this.aktionenSwiper, $(event.currentTarget));
            });

        $(".smb-startseite .smb-popup-design-contest .smb-close-button").on("click", (event: JQuery.TriggeredEvent) => {
            $(event.currentTarget).closest(".smb-popup-design-contest").toggleClass("smb-hide", true);
            $(".smb-startseite .smb-overlay").toggleClass("smb-hide", true);
        });

        $(".smb-startseite .smb-overlay").on("click", (event: JQuery.TriggeredEvent) => {
            $(event.currentTarget).toggleClass("smb-hide", true);
            $(".smb-startseite .smb-popup-design-contest").toggleClass("smb-hide", true);
        });
    }

    private getLocalStorageValue(key: string): string {
        const now: number = Date.now();
        const expiresIn: string | null = localStorage.getItem(`${key}_expiresIn`);

        let expiredDate: number = 0;

        if (expiresIn !== null && expiresIn.length > 0) {
            expiredDate = parseInt(expiresIn as string, 10);
        }

        if (expiredDate < now) {
            this.removeLocalStorage(key);

            return "";
        } else {
            return localStorage.getItem(key) as string;
        }
    }

    private handleLocalStorage() {
        const value: string = this.getLocalStorageValue("smb-caseable-popup");

        if (value) {
            $(".smb-startseite .smb-overlay").toggleClass("smb-hide", true);
            $(".smb-startseite .smb-popup-design-contest").toggleClass("smb-hide", true);
        }
        else {
            this.setLocalStorage("smb-caseable-popup", "true", 1);
        }
    }

    private handleSwiperAutoPlay(swiper: Swiper, buttonContainer: JQuery<Element>): void {
        const isAutoplayRunning: boolean = swiper.autoplay.running;

        buttonContainer.find(".pause").toggleClass("smb-hide", isAutoplayRunning);
        buttonContainer.find(".play").toggleClass("smb-hide", !isAutoplayRunning);

        if (!isAutoplayRunning) {
            swiper.autoplay.start();
        }
        else {
            swiper.autoplay.stop();
        }
    }

    private isMobile(): boolean {
        return $(".smb-page").hasClass("smb-mobile");
    }

    private removeLocalStorage(key: string): void {
        localStorage.removeItem(key);
        localStorage.removeItem(`${key}_expiresIn`);
    }

    private setLocalStorage(key: string, value: any, expireDays: number): void {
        const expires = (expireDays * 24 * 60 * 60 * 1000);
        const now: number = Date.now();
        const schedule: number = now + expires;

        localStorage.setItem(key, value);
        localStorage.setItem(`${key}_expiresIn`, schedule.toString());
    }
}

$((): void => {
    if ($(".smb-startseite").length > 0) {
        new Startseite();
    }
});