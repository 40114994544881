import _ from "lodash";
import { MainNavigationEntryModel, NavigationModel, SubNavigationEntryModel } from "../Models/MenuModel";
//import { SvgService } from "./Svg-Service";

export class MenuMobileHtmlService {
    public static getMobileMenuHtml(navigationModel: NavigationModel, isStartpage: boolean): string {
        const menuHtml: string[] = [];

        menuHtml.push(this.createMainNavigation(navigationModel.mainNavigation.mobilelogoURL, isStartpage));
        menuHtml.push(this.createSubNavigation(navigationModel));

        return menuHtml.join("");
    }

    private static createMainNavigation(logoUrl: string, isStartpage: boolean): string {
        const mainNavigationHtml: string[] = [];

        mainNavigationHtml.push(`<div class="smb-mobile-main-navigation">`);
        mainNavigationHtml.push(this.createMainNavigationMenuButton());
        mainNavigationHtml.push(this.createMainNavigationLogo(logoUrl));
        mainNavigationHtml.push(this.createMainNavigationFoundLostButton(isStartpage));
        mainNavigationHtml.push("</div>");

        return mainNavigationHtml.join("");
    }
    private static createMainNavigationFoundLostButton(isStartpage: boolean): string {
        //const alarmSvgHtml: string = SvgService.getSvg("smb-icon-alarm", true);
        const foundContainerClasses: string = this.getFoundContainerCssClasses(isStartpage);

        // return `<div class="${foundContainerClasses}">
        //            <div class="smb_found_icon foundIn">
        //                ${alarmSvgHtml}
        //            </div>
        //            <div class="smb_found_text foundOut">
        //                <div>Fund / Verlust melden</div>
        //            </div>
        //        </div>`;

        return `<div class="${foundContainerClasses}">
                    <div class="smb_found_icon foundIn">
                    </div>
                    <div class="smb_found_text foundOut">
                        <div>Fund / Verlust melden</div>
                    </div>
                </div>`;
    }
    private static createMainNavigationLogo(logoUrl: string): string {
        return `<div class="smb-logo-conatiner">
                    <a href="/">
                        <div class="smb-logo">
                            <img src="${logoUrl}" height="50" width="160" alt="Sendmeback-Logo">
                        </div>
                    </a>
                </div>`;
    }
    private static createMainNavigationMenuButton(): string {
        return `<div class="smb-menu-button-container">
                    <div class="smb-menu-button">
                        <div class="smb-menu-button-line1"></div>
                        <div class="smb-menu-button-line2"></div>
                        <div class="smb-menu-button-line3"></div>
                    </div>
                </div>`;
    }

    private static createSubNavigation(navigationModel: NavigationModel): string {
        const subNavigationHtml: string[] = [];

        subNavigationHtml.push(`<div class="smb-mobile-sub-navigation">`);
        subNavigationHtml.push(
            this.createSubNavigationTopEntries(
                navigationModel.mainNavigation.leftNavigationEntries,
                navigationModel.subNavigation.subNavigationEntries,
            ),
        );
        subNavigationHtml.push(
            this.createSubNavigationBottomEntries(
                navigationModel.mainNavigation.rightNavigationEntries,
                navigationModel.subNavigation.subNavigationEntries,
            ),
        );
        subNavigationHtml.push(`</div>`);

        return subNavigationHtml.join("");
    }
    private static createSubNavigationBottomAccountEntry(
        mainNavigationEntry: MainNavigationEntryModel,
        subNavigationEntries: SubNavigationEntryModel[],
    ): string {
        const subNavigationBottomAccountEntryHtml: string[] = [];
        subNavigationBottomAccountEntryHtml.push(`<div class="smb-mobile-sub-navigation-account-entry">`);

        const subNavigationEntriesByMainNavigationCategory =
            this.getSubNavigationEntriesByMainNavigationCategory(mainNavigationEntry, subNavigationEntries);

        for (const subNavigationEntry of subNavigationEntriesByMainNavigationCategory) {
            subNavigationBottomAccountEntryHtml.push(this.createSubNavigationBottomSubEntry(subNavigationEntry));
        }

        subNavigationBottomAccountEntryHtml.push(`</div>`);

        return subNavigationBottomAccountEntryHtml.join("");
    }

    private static createSubNavigationBottomEntries(
        rightNavigationEntries: MainNavigationEntryModel[],
        subNavigationEntries: SubNavigationEntryModel[],
    ): string {
        const subNavigationBottomEntriesHtml: string[] = [];
        subNavigationBottomEntriesHtml.push(`<div class="smb-mobile-sub-navigation-bottom-entries">`);

        rightNavigationEntries.unshift(rightNavigationEntries.pop() as MainNavigationEntryModel);

        const accountNavigationEntry: MainNavigationEntryModel = _.take(rightNavigationEntries)[0];
        const foundLostNavigationEntries: MainNavigationEntryModel[] = _.takeRight(rightNavigationEntries, 2);

        subNavigationBottomEntriesHtml.push(
            this.createSubNavigationBottomAccountEntry(accountNavigationEntry, subNavigationEntries),
        );

        subNavigationBottomEntriesHtml.push(`<div class="smb-mobile-sub-navigation-main-entries">`);

        for (const mainRightNavigationEntry of foundLostNavigationEntries) {
            subNavigationBottomEntriesHtml.push(this.createSubNavigationBottomMainEntry(mainRightNavigationEntry));
        }

        subNavigationBottomEntriesHtml.push(`</div>`);

        // for (const mainRightNavigationEntry of rightNavigationEntries) {
        //    if (mainRightNavigationEntry.isAccount) {
        //        subNavigationBottomEntriesHtml.push(
        //            this.createSubNavigationBottomAccountEntry(mainRightNavigationEntry, subNavigationEntries),
        //        );
        //    } else {
        //        subNavigationBottomEntriesHtml
        //          .push(this.createSubNavigationBottomMainEntry(mainRightNavigationEntry));
        //    }
        // }

        subNavigationBottomEntriesHtml.push(`</div>`);

        return subNavigationBottomEntriesHtml.join("");
    }
    private static createSubNavigationBottomMainEntry(mainNavigationEntry: MainNavigationEntryModel): string {
        const bottomMainEntryHtml: string[] = [];
        const entryTextClassName: string[] = ["smb-mobile-sub-navigation-main-entry-text"];

        if (mainNavigationEntry.isFound) {
            entryTextClassName.push("smb-is-found");
        } else if (mainNavigationEntry.isLost) {
            entryTextClassName.push("smb-is-lost");
        }

        if (mainNavigationEntry.link) {
            if (mainNavigationEntry.isExternalLink) {
                bottomMainEntryHtml.push(`<a href="${mainNavigationEntry.link}" rel="noopener noreferrer" target="_blank">`);
            }
            else {
                bottomMainEntryHtml.push(`<a href="${mainNavigationEntry.link}">`);
            }
        }

        bottomMainEntryHtml.push(`<div class="smb-mobile-sub-navigation-main-entry">
                    <div class="${entryTextClassName.join(" ")}">
                        ${mainNavigationEntry.text}
                    </div>
                </div>`);

        if (mainNavigationEntry.link) {
            bottomMainEntryHtml.push(`</a>`);
        }

        return bottomMainEntryHtml.join("");
    }
    private static createSubNavigationBottomSubEntry(subNavigationEntry: SubNavigationEntryModel): string {
        const subNavigationBottomSubEntryHtml: string[] = [];

        if (subNavigationEntry.isExternalLink) {
            subNavigationBottomSubEntryHtml.push(`<a href="${subNavigationEntry.link}" rel="noopener noreferrer" target="_blank">`);
        }
        else {
            subNavigationBottomSubEntryHtml.push(`<a href="${subNavigationEntry.link}">`);
        }

        subNavigationBottomSubEntryHtml.push(`<div class="smb-mobile-sub-navigation-sub-entry">`);
        subNavigationBottomSubEntryHtml.push(
            `<div class="smb-mobile-sub-navigation-sub-entry-text">${subNavigationEntry.text}</div>`,
        );
        subNavigationBottomSubEntryHtml.push(`</div>`);
        subNavigationBottomSubEntryHtml.push(`</a>`);

        return subNavigationBottomSubEntryHtml.join("");
    }

    private static createSubNavigationTopEntries(
        leftNavigationEntries: MainNavigationEntryModel[],
        subNavigationEntries: SubNavigationEntryModel[],
    ): string {
        const subNavigationTopEntriesHtml: string[] = [];

        subNavigationTopEntriesHtml.push(`<div class="smb-mobile-sub-navigation-top-entries">`);

        for (const mainLeftNavigationEntry of leftNavigationEntries) {
            subNavigationTopEntriesHtml.push(this.createSubNavigationTopMainEntry(mainLeftNavigationEntry));
            subNavigationTopEntriesHtml.push(
                this.createSubNavigationTopSubEntries(mainLeftNavigationEntry, subNavigationEntries),
            );
        }

        subNavigationTopEntriesHtml.push(`</div>`);

        return subNavigationTopEntriesHtml.join("");
    }
    private static createSubNavigationTopMainEntry(mainNavigationEntry: MainNavigationEntryModel): string {
        const mainNavigationEntryHtml: string[] = [];
        const mainEntryClass: string[] = ["smb-mobile-sub-navigation-main-entry"];

        if (mainNavigationEntry.isActive) {
            mainEntryClass.push("smb-is-active");
        }

        if (mainNavigationEntry.link) {
            if (mainNavigationEntry.isExternalLink) {
                mainNavigationEntryHtml.push(`<a href="${mainNavigationEntry.link}" rel="noopener noreferrer" target="_blank">`);
            }
            else {
                mainNavigationEntryHtml.push(`<a href="${mainNavigationEntry.link}">`);
            }
        }

        mainNavigationEntryHtml.push(`<div class="${mainEntryClass.join(" ")}"
                     data-category="${mainNavigationEntry.category}">
                     ${mainNavigationEntry.text}
                </div>`);

        if (mainNavigationEntry.link) {
            mainNavigationEntryHtml.push(`</a>`);
        }

        return mainNavigationEntryHtml.join("");
    }
    private static createSubNavigationTopSubEntries(
        mainNavigationEntry: MainNavigationEntryModel,
        subNavigationEntries: SubNavigationEntryModel[],
    ): string {
        const subNavigationTopSubEntriesHtml: string[] = [];
        const subEntriesClass: string[] = ["smb-mobile-sub-navigation-sub-entries"];

        if (mainNavigationEntry.isActive) {
            subEntriesClass.push("smb-is-active");
        }
        else {
            subEntriesClass.push("smb-hide");
        }

        subNavigationTopSubEntriesHtml.push(
            `<div class="${subEntriesClass.join(" ")}" data-paranet-category="${mainNavigationEntry.category}">`,
        );

        const subNavigationEntriesByMainNavigationCategory =
            this.getSubNavigationEntriesByMainNavigationCategory(mainNavigationEntry, subNavigationEntries);

        for (const subNavigationEntry of subNavigationEntriesByMainNavigationCategory) {
            subNavigationTopSubEntriesHtml.push(this.createSubNavigationTopSubEntry(subNavigationEntry));
        }

        subNavigationTopSubEntriesHtml.push(`</div>`);

        return subNavigationTopSubEntriesHtml.join("");
    }
    private static createSubNavigationTopSubEntry(subNavigationEntry: SubNavigationEntryModel): string {
        const subNavigationTopSubEntryHtml: string[] = [];

        const subEntryClass: string[] = ["smb-mobile-sub-navigation-sub-entry"];

        if (subNavigationEntry.isActive) {
            subEntryClass.push("smb-is-active");
        }

        subNavigationTopSubEntryHtml.push(`<a href="${subNavigationEntry.link}">`);
        subNavigationTopSubEntryHtml.push(`<div class="${subEntryClass.join(" ")}">${subNavigationEntry.text}</div>`);
        subNavigationTopSubEntryHtml.push(`</a>`);

        return subNavigationTopSubEntryHtml.join("");
    }
    private static getFoundContainerCssClasses(isStartpage: boolean): string {
        const foundContainerClasses: string[] = ["smb_found_container"];

        //if (isStartpage) {
        //    foundContainerClasses.push("foundanimation");
        //} else {
            foundContainerClasses.push("foundanimation_fixed");
        //}

        return foundContainerClasses.join(" ");
    }

    private static getSubNavigationEntriesByMainNavigationCategory(
        mainLeftNavigationEntry: MainNavigationEntryModel,
        subNavigationEntries: SubNavigationEntryModel[],
    ): SubNavigationEntryModel[] {
        return subNavigationEntries.filter((subNavigationEntry: SubNavigationEntryModel) => {
            return subNavigationEntry.category === mainLeftNavigationEntry.category;
        });
    }
}