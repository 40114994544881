export class SvgService {
    public static getSvg(svgkey: string, isWhiteSvg: boolean): string {
        const blackSvgs: { [index: string]: string } = {
            "smb-icon-alarm": "<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='notification_x5F_bell_1_' d='M27,24c-0.6,0-1-0.4-1-1v-5c0-3.6-2.4-5.5-5-5.9V11c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v1.1 c-2.6,0.4-5,2.4-5,5.9v5c0,0.6-0.4,1-1,1c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4v1c0,1.7,1.3,3,3,3s3-1.3,3-3v-1h4c0.6,0,1-0.4,1-1 C28,24.4,27.6,24,27,24z M21,27c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-1h2V27z M24,24h-8v-6c0-2.7,2-4,4-4h0c2,0,4,1.3,4,4V24z'/> </svg>",
            "smb-icon-arrow-down": "<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='play_1_' d='M21.1,23l2.8-4.9c0.5-0.8-0.1-1.8-1.1-1.8h-5.6c-0.9,0-1.5,1-1.1,1.8l2.8,4.9C19.4,23.9,20.6,23.9,21.1,23z'/> </svg>",
            "smb-icon-arrow-up": "<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='play_2_' d='M18.9,17l-2.8,4.9c-0.5,0.8,0.1,1.8,1.1,1.8h5.6c0.9,0,1.5-1,1.1-1.8L21.1,17C20.6,16.1,19.4,16.1,18.9,17z'/> </svg>",
            "smb-icon-clock": "<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='clock_1_' d='M26,21c0,0.6-0.4,1-1,1h-4c-1.1,0-2-0.9-2-2v-5c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v4c0,0.5,0.5,1,1,1h3 C25.6,20,26,20.4,26,21z M30,20c0,5.5-4.5,10-10,10s-10-4.5-10-10c0-5.5,4.5-10,10-10S30,14.5,30,20z M28,20c0-4.4-3.6-8-8-8 s-8,3.6-8,8c0,4.4,3.6,8,8,8S28,24.4,28,20z'/> </svg>",
            "smb-icon-contact": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='message_2_' d='M20,12c4,0,7,2.7,7.8,6.1c0.3,1.2,0.2,2.5-0.1,3.6c-1.3,5,2.6,7-1.8,5.9c-1.5-0.4-3-0.5-4.5-0.2 c-1.2,0.2-2.3,0.1-3.5-0.2c-3-0.8-5.3-3.4-5.8-6.4C11.4,16,15.3,12,20,12L20,12 M20,10c-6,0-10.7,5.1-9.9,11.1c0.5,3.8,3.4,7,7.2,8 c1.4,0.4,2.9,0.5,4.3,0.2c1.2-0.2,2.5-0.1,3.7,0.2l1.5,0.4l0,0c1.9,0.5,3.6-1.2,3.1-3.1c0,0-0.3-1-0.3-1.1c-0.3-1.2-0.4-2.4-0.1-3.5 c0.4-1.4,0.5-3,0.1-4.6C28.8,13.4,24.9,10,20,10L20,10z'/> </svg>",
            "smb-icon-facebook": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='facebook_2_' d='M21.9,30v-9h2.8l0.5-4h-3.3v-1.9c0-1,0-2.1,1.5-2.1h1.5v-2.9c0,0-1.3-0.1-2.6-0.1c-2.7,0-4.4,1.7-4.4,4.7 V17h-3v4h3v9H21.9z'/> </svg>",
            "smb-icon-heart": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='love_1_' d='M20.4,14.3c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.4-0.2c-3.5-4.3-9.8-2.2-9.6,3.2 c0.2,4.2,3.2,6.3,6.7,9.3c0.9,0.8,2.1,1.2,3.3,1.2c1.2,0,2.4-0.4,3.3-1.2c3.5-3,6.5-5.1,6.7-9.2C30.3,12.1,23.9,10,20.4,14.3z M22,25.3c-0.5,0.5-1.3,0.7-2,0.7c-0.7,0-1.4-0.3-2-0.7c-3.6-3-5.9-4.6-6-7.9c-0.1-3.5,3.7-4.8,6-1.9c0.5,0.6,1.2,0.9,2,0.9 c0.8,0,1.5-0.3,2-0.9c2.3-2.8,6.2-1.6,6,2C27.8,20.7,25.5,22.4,22,25.3z'/> </svg>",
            "smb-icon-help": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='question_1_' d='M10,12v16c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V12c0-1.1-0.9-2-2-2H12C10.9,10,10,10.9,10,12z M27,28H13 c-0.6,0-1-0.4-1-1V13c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v14C28,27.6,27.6,28,27,28z M15.8,15c0-0.6,0.4-1,1-1h5.7 c0.8,0,1.5,0.7,1.5,1.5V18c0,1.1-0.9,2-2,2h-1.5c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1.7c0-1,0.8-1.8,1.8-1.8h1 c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1h-4C16.2,16,15.8,15.6,15.8,15z M19.8,24.5c0,0.6-0.4,1-1,1s-1-0.4-1-1c0-0.6,0.4-1,1-1 S19.8,24,19.8,24.5z'/> </svg>",
            "smb-icon-house": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='home' d='M28.5,18c0-0.3-0.1-0.5-0.3-0.7l-6.7-6.7c-0.8-0.8-2.2-0.8-3,0l-6.7,6.6c-0.2,0.2-0.3,0.5-0.3,0.7v10.2 c0,1.2,1,1.9,2.1,1.9h3.2c1.2,0,2.1-0.7,2.1-1.9v-1.1c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1v1.1c0,1.2,1,1.9,2.1,1.9h3.2 c1.2,0,2.1-0.7,2.1-1.9L28.5,18z M26.4,27.1c0,0.6-0.5,0.8-1.1,0.8h-1.1c-0.6,0-1.1-0.2-1.1-0.8V26c0-1.2-1-2.3-2.1-2.3h-2.1 c-1.2,0-2.1,1.2-2.1,2.3v1.1c0,0.6-0.5,0.8-1.1,0.8h-1.1c-0.6,0-1.1-0.2-1.1-0.8v-8.5c0-0.1,0.1-0.3,0.2-0.4l5.5-5.4 c0.4-0.4,1.1-0.4,1.5,0l5.5,5.4c0.1,0.1,0.2,0.2,0.2,0.4V27.1z'/> </svg>",
            "smb-icon-instagram": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='instagram' d='M16.7,20c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3S16.7,21.8,16.7,20 M14.9,20 c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S14.9,17.2,14.9,20 M24.1,14.7c0,0.7,0.5,1.2,1.2,1.2 c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2C24.6,13.5,24.1,14,24.1,14.7 M16,28.1c-1,0-1.5-0.2-1.8-0.3c-0.9-0.3-1.5-1-1.9-1.9 c-0.6-1.5-0.4-8.7-0.3-9.9c0.1-1.1,0.3-2.2,1.1-3c1-1,2.3-1.5,11-1.1c1.1,0.1,2.2,0.3,3,1.1c1,1,1.5,2.3,1.1,11c0,1-0.2,1.5-0.3,1.8 C26.8,28.2,24.7,28.5,16,28.1 M15.9,10.1c-1.6,0.1-3,0.5-4.2,1.6c-1.1,1.1-1.5,2.6-1.6,4.2c0,1-0.3,8.6,0.5,10.6 c0.5,1.3,1.6,2.4,2.9,2.9c0.6,0.2,1.4,0.4,2.4,0.5c8.8,0.4,12.1,0.2,13.5-3.4c0.2-0.6,0.4-1.4,0.5-2.4c0.4-8.9-0.1-10.8-1.6-12.4 C27,10.5,25.6,9.7,15.9,10.1'/> </svg>",
            "smb-icon-login": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='profile_x5F_round_2_' d='M30,27.6c-0.7-3.4-3.1-5.8-6.1-7c1.6-1.3,2.6-3.3,2.2-5.6c-0.4-2.6-2.6-4.7-5.3-5 c-3.7-0.4-6.9,2.4-6.9,6c0,1.9,0.9,3.6,2.3,4.7c-3.1,1.1-5.4,3.6-6.1,7c-0.3,1.2,0.7,2.4,2,2.4h15.9C29.2,30,30.2,28.9,30,27.6z M15.9,16c0-2.2,1.8-4,4.1-4s4.1,1.8,4.1,4s-1.8,4-4.1,4S15.9,18.2,15.9,16z M26.6,28H13.4c-0.7,0-1.2-0.7-1-1.3 c1.2-3,4.1-4.7,7.5-4.7c3.4,0,6.3,1.7,7.5,4.7C27.8,27.3,27.3,28,26.6,28z'/> </svg>",
            "smb-icon-products": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='arrow_x5F_up_15_' d='M18.6,10.6l-4.3,4.2C14.1,15,14,15.2,14,15.5V28c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V15.5 c0-0.3-0.1-0.5-0.3-0.7l-4.3-4.2C20.6,9.8,19.4,9.8,18.6,10.6z M23,28h-6c-0.6,0-1-0.4-1-1V16.1c0-0.1,0.1-0.3,0.1-0.4l3.1-3.1 c0.4-0.4,1-0.4,1.4,0l3.2,3.1c0.1,0.1,0.2,0.2,0.2,0.4V27C24,27.6,23.5,28,23,28z'/> </svg>",
            "smb-icon-start": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='mouse_x5F__x2191__x5F_window_2_' d='M18.8,22.4l-2.3,6.1c-0.3,0.7-1.1,0.9-1.6,0.4l-1.2-1.2l-2,2c-0.4,0.4-1,0.4-1.4,0 l0,0c-0.4-0.4-0.4-1,0-1.4l2-2l-1.2-1.2c-0.5-0.5-0.3-1.4,0.4-1.6l6.1-2.3C18.4,20.9,19.1,21.6,18.8,22.4z M28,10H12 c-1.1,0-2,0.9-2,2v5c0,0.6,0.4,1,1,1s1-0.4,1-1v-4c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v14c0,0.6-0.4,1-1,1h-4c-0.6,0-1,0.4-1,1 c0,0.6,0.4,1,1,1h5c1.1,0,2-0.9,2-2V12C30,10.9,29.1,10,28,10z'/> </svg>",
            "smb-icon-tiktok": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <g> <path d='M19.9,22c-1-0.8-2.1-0.7-2.9-0.2c-0.9,0.6-1.3,1.8-0.9,2.9c0.4,1,1.5,1.6,2.6,1.4c1.1-0.2,1.9-1.2,1.9-2.3 c0-3.3,0-6.6,0-9.9c0-1.2,0-2.4,0-3.5c0-0.1,0-0.1,0-0.2c1.3,0,2.6,0,3.9,0c0,0.1,0,0.2,0,0.3c0.1,0.5,0.1,0.9,0.2,1.4 c0.3,1.3,1.3,2,2.6,1.9c0.2,0,0.4,0,0.6-0.1c0,0.1,0,0.1,0,0.2c0,1.2,0,2.4,0,3.5c0,0.2,0,0.2-0.2,0.2c-1,0.1-2.1,0-3-0.4 c-0.1,0-0.1,0-0.2-0.1c0,0.1,0,0.2,0,0.3c0,2.1,0,4.3,0,6.4c0,3.2-2.3,5.8-5.5,6.3c-3.5,0.5-6.7-2-7.1-5.5c-0.4-3.4,1.9-6.5,5.3-7 c0.8-0.1,1.6-0.1,2.4,0.1c0.1,0,0.2,0.1,0.2,0.2c0,1.4,0,2.7,0,4.1C19.9,21.9,19.9,21.9,19.9,22z'/> </g> </svg>",
            "smb-icon-umbrella": "<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='umbrella_x5F_round_1_' d='M20.5,10c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0 c-5.3,0.3-9.5,4.6-9.5,10c0,0.3,0,1,0.1,1h2h3.4h2H19v6c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1c0-0.6-0.4-1-1-1h0c-0.6,0-1,0.4-1,1v2 c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-7h1.5h2h3.4h2c0,0,0.1-0.7,0.1-1C30,14.7,25.8,10.3,20.5,10z M15.5,19h-3.4 c0.4-3,2.6-5.8,5.6-6.7C16.4,14.4,15.7,17,15.5,19z M21,19h-2h-1.5c0.2-2,1.1-4.5,2.5-6.5c1.4,1.9,2.3,4.5,2.5,6.5H21z M24.5,19 c-0.2-2-0.9-4.6-2.2-6.7c3,0.9,5.2,3.7,5.6,6.7H24.5z' /> </svg>",
            "smb-icon-warning": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='important_x5F_message_1_' d='M28,10H12c-1.1,0-2,0.9-2,2v0v16c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V12C30,10.9,29,10,28,10 z M28,27c0,0.6-0.4,1-1,1H13c-0.6,0-1-0.4-1-1V13c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1V27z M21,25c0,0.6-0.4,1-1,1s-1-0.4-1-1 c0-0.6,0.4-1,1-1S21,24.5,21,25z M21,15v7c0,0.6-0.4,1-1,1s-1-0.4-1-1v-7c0-0.6,0.4-1,1-1S21,14.5,21,15z'/> </svg>",
            "smb-icon-youtube": "<svg xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path id='youtube' d='M29.1,14.2c-0.3-0.5-0.9-0.8-1.6-0.9c-1.8-0.3-13.3-0.3-15.1,0c-0.5,0.1-0.9,0.3-1.3,0.7 c-1.6,1.5-1.1,9.5-0.7,10.8c0.2,0.6,0.4,1,0.6,1.2c0.3,0.4,0.8,0.6,1.4,0.7c1.5,0.3,9.3,0.5,15.1,0c0.5-0.1,1-0.3,1.4-0.7 C30.4,24.5,30.3,16.1,29.1,14.2z M18,22.6c0-1.9,0-3.7,0-5.6c2,0.9,3.5,1.8,5.4,2.8C21.8,20.6,20,21.6,18,22.6z'/> </svg>",
        };

        const whiteSvgs: { [index: string]: string } = {
            "smb-icon-alarm": "<svg  xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='45px' height='45px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path fill='#FFFFFF' d='M20,33.7c1.8,0,3.2-1.4,3.2-3.2h-6.3C16.9,32.3,18.3,33.7,20,33.7z M30.7,26.3c-1-1-2.7-2.6-2.7-7.6 c0-3.8-2.7-6.9-6.3-7.7v-1c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v1c-3.6,0.8-6.3,3.8-6.3,7.7c0,5.1-1.8,6.6-2.7,7.6 c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.8,0.6,1.6,1.6,1.6h19c0.9,0,1.6-0.8,1.6-1.6C31.1,27,31,26.6,30.7,26.3z'/> <path fill='none' stroke='#FFFFFF' stroke-miterlimit='10' d='M32.7,25.5c2.4-2.7,2.4-6.5,0.3-9.3'/> <path fill='none' stroke='#FFFFFF' stroke-miterlimit='10' d='M34.6,29.2c4.4-5,4.4-12,0.5-17.1'/> <path fill='none' stroke='#FFFFFF' stroke-miterlimit='10' d='M7.1,16.1C5,18.9,5,22.7,7.3,25.5'/> <path fill='none' stroke='#FFFFFF' stroke-miterlimit='10' d='M4.9,12c-3.9,5.1-3.9,12.1,0.5,17.1'/> </svg>",
            "smb-icon-facebook": "<svg  xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path fill='#FFFFFF' id='facebook_2_' d='M21.9,30v-9h2.8l0.5-4h-3.3v-1.9c0-1,0-2.1,1.5-2.1h1.5v-2.9c0,0-1.3-0.1-2.6-0.1c-2.7,0-4.4,1.7-4.4,4.7 V17h-3v4h3v9H21.9z'/> </svg>",
            "smb-icon-instagram": "<svg  xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path fill='#FFFFFF' id='instagram' d='M16.7,20c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3s-1.5,3.3-3.3,3.3S16.7,21.8,16.7,20 M14.9,20 c0,2.8,2.3,5.1,5.1,5.1s5.1-2.3,5.1-5.1s-2.3-5.1-5.1-5.1S14.9,17.2,14.9,20 M24.1,14.7c0,0.7,0.5,1.2,1.2,1.2 c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2C24.6,13.5,24.1,14,24.1,14.7 M16,28.1c-1,0-1.5-0.2-1.8-0.3c-0.9-0.3-1.5-1-1.9-1.9 c-0.6-1.5-0.4-8.7-0.3-9.9c0.1-1.1,0.3-2.2,1.1-3c1-1,2.3-1.5,11-1.1c1.1,0.1,2.2,0.3,3,1.1c1,1,1.5,2.3,1.1,11c0,1-0.2,1.5-0.3,1.8 C26.8,28.2,24.7,28.5,16,28.1 M15.9,10.1c-1.6,0.1-3,0.5-4.2,1.6c-1.1,1.1-1.5,2.6-1.6,4.2c0,1-0.3,8.6,0.5,10.6 c0.5,1.3,1.6,2.4,2.9,2.9c0.6,0.2,1.4,0.4,2.4,0.5c8.8,0.4,12.1,0.2,13.5-3.4c0.2-0.6,0.4-1.4,0.5-2.4c0.4-8.9-0.1-10.8-1.6-12.4 C27,10.5,25.6,9.7,15.9,10.1'/> </svg>",
            "smb-icon-tiktok": "<svg  xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <g> <path fill='#FFFFFF' d='M19.9,22c-1-0.8-2.1-0.7-2.9-0.2c-0.9,0.6-1.3,1.8-0.9,2.9c0.4,1,1.5,1.6,2.6,1.4c1.1-0.2,1.9-1.2,1.9-2.3 c0-3.3,0-6.6,0-9.9c0-1.2,0-2.4,0-3.5c0-0.1,0-0.1,0-0.2c1.3,0,2.6,0,3.9,0c0,0.1,0,0.2,0,0.3c0.1,0.5,0.1,0.9,0.2,1.4 c0.3,1.3,1.3,2,2.6,1.9c0.2,0,0.4,0,0.6-0.1c0,0.1,0,0.1,0,0.2c0,1.2,0,2.4,0,3.5c0,0.2,0,0.2-0.2,0.2c-1,0.1-2.1,0-3-0.4 c-0.1,0-0.1,0-0.2-0.1c0,0.1,0,0.2,0,0.3c0,2.1,0,4.3,0,6.4c0,3.2-2.3,5.8-5.5,6.3c-3.5,0.5-6.7-2-7.1-5.5c-0.4-3.4,1.9-6.5,5.3-7 c0.8-0.1,1.6-0.1,2.4,0.1c0.1,0,0.2,0.1,0.2,0.2c0,1.4,0,2.7,0,4.1C19.9,21.9,19.9,21.9,19.9,22z'/> </g> </svg>",
            "smb-icon-youtube": "<svg  xmlns='http://www.w3.org/2000/svg'  x='0px' y='0px' width='40px' height='40px' viewBox='0 0 40 40' enable-background='new 0 0 40 40'> <path fill='#FFFFFF' id='youtube' d='M29.1,14.2c-0.3-0.5-0.9-0.8-1.6-0.9c-1.8-0.3-13.3-0.3-15.1,0c-0.5,0.1-0.9,0.3-1.3,0.7 c-1.6,1.5-1.1,9.5-0.7,10.8c0.2,0.6,0.4,1,0.6,1.2c0.3,0.4,0.8,0.6,1.4,0.7c1.5,0.3,9.3,0.5,15.1,0c0.5-0.1,1-0.3,1.4-0.7 C30.4,24.5,30.3,16.1,29.1,14.2z M18,22.6c0-1.9,0-3.7,0-5.6c2,0.9,3.5,1.8,5.4,2.8C21.8,20.6,20,21.6,18,22.6z'/> </svg>",
        };

        if (isWhiteSvg) {
            return whiteSvgs[svgkey];
        }

        return blackSvgs[svgkey];
    }
}