import {
    MainNavigationEntryModel,
    MainNavigationModel,
    NavigationModel,
    SubNavigationEntryModel,
    SubNavigationModel,
} from "../Models/MenuModel";

export class MenuHtmlService {
    public static getMenuHtml(navigationModel: NavigationModel): string {
        const menuHtml: string[] = [];

        menuHtml.push(this.createMainNavigation(navigationModel.mainNavigation));
        menuHtml.push(this.createSubNavigation(navigationModel.subNavigation));

        return menuHtml.join("");
    }
    private static createLogoHtml(logoURL: string): string {
        return `<div class="smb-logo-conatiner">
                    <a href="/">
                        <div class="smb-logo">
                            <img src="${logoURL}" height="60" width="60" alt="Sendmeback-Logo">
                        </div>
                    </a>
                </div>`;
    }

    private static createMainNavigation(mainNavigationModel: MainNavigationModel): string {
        const mainNavigationHtml: string[] = [];

        mainNavigationHtml.push(`<div class="smb-main-navigation">`);
        mainNavigationHtml.push(this.createLogoHtml(mainNavigationModel.logoURL));
        mainNavigationHtml.push(this.createMainNavigationEntriesHtml(mainNavigationModel));
        mainNavigationHtml.push(`</div>`);

        return mainNavigationHtml.join("");
    }
    private static createMainNavigationEntriesHtml(mainNavigationModel: MainNavigationModel): string {
        const mainNavigationEntriesContainerHtml: string[] = [];

        mainNavigationEntriesContainerHtml.push(`<div class="smb-navigation-entries-container">`);
        mainNavigationEntriesContainerHtml.push(`<nav class="smb-left-navigation-entries">`);

        for (const leftNavigationEntry of mainNavigationModel.leftNavigationEntries) {
            mainNavigationEntriesContainerHtml.push(this.createMainNavigationEntryHtml(leftNavigationEntry));
        }
        mainNavigationEntriesContainerHtml.push(`</nav>`);

        mainNavigationEntriesContainerHtml.push(`<nav class="smb-right-navigation-entries">`);
        for (const rightNavigationEntry of mainNavigationModel.rightNavigationEntries) {
            mainNavigationEntriesContainerHtml.push(this.createMainNavigationEntryHtml(rightNavigationEntry));
        }
        mainNavigationEntriesContainerHtml.push(`</nav>`);
        mainNavigationEntriesContainerHtml.push(`</div>`);

        return mainNavigationEntriesContainerHtml.join("");
    }
    private static createMainNavigationEntryHtml(mainNavigationEntry: MainNavigationEntryModel): string {
        const mainNavigationEntriesHtml: string[] = [];
        const className: string[] = ["smb-main-navigation-entry"];

        if (mainNavigationEntry.isFound) {
            className.push("smb-is-found");
        }
        else if (mainNavigationEntry.isLost) {
            className.push("smb-is-lost");
        }
        else if (mainNavigationEntry.isAccount) {
            className.push("smb-is-account");
        }
        else if (mainNavigationEntry.isFaq) {
            className.push("smb-is-faq");
        }

        if (mainNavigationEntry.isActive) {
            className.push("smb-is-active");
        }

        if (mainNavigationEntry.link) {
            if (mainNavigationEntry.isExternalLink) {
                mainNavigationEntriesHtml.push(`<a href="${mainNavigationEntry.link}" rel="noopener noreferrer" target="_blank">`);
            }
            else {
                mainNavigationEntriesHtml.push(`<a href="${mainNavigationEntry.link}">`);
            }
        }

        mainNavigationEntriesHtml.push(`<div class="${className.join(" ")}" data-category="${mainNavigationEntry.category}">${mainNavigationEntry.text}</div>`);

        if (mainNavigationEntry.link) {
            mainNavigationEntriesHtml.push(`</a>`);
        }

        return mainNavigationEntriesHtml.join("");
    }

    private static createSubNavigation(subNavigation: SubNavigationModel): string {
        const subNavigationHtml: string[] = [];

        subNavigationHtml.push(`<div class="smb-sub-navigation smb-hide">`);
        subNavigationHtml.push(this.createSubNavigationEntriesHtml(subNavigation.subNavigationEntries));
        subNavigationHtml.push(`</div>`);
        subNavigationHtml.push(`</div>`);

        return subNavigationHtml.join("");
    }
    private static createSubNavigationEntriesHtml(subNavigationEntries: SubNavigationEntryModel[]): string {
        const subNavigationEntriesHtml: string[] = [];
        let category: string = "";

        for (const subNavigationEntry of subNavigationEntries) {
            if (category && category !== subNavigationEntry.category) {
                subNavigationEntriesHtml.push(`</div>`);
            }

            if (category !== subNavigationEntry.category) {
                category = subNavigationEntry.category;
                subNavigationEntriesHtml.push(`<div class="smb-sub-navigation-entries smb-hide" data-parent-category="${subNavigationEntry.category}">`);
            }

            subNavigationEntriesHtml.push(this.createSubNavigationEntryHtml(subNavigationEntry));
        }

        return subNavigationEntriesHtml.join("");
    }
    private static createSubNavigationEntryHtml(subNavigationEntry: SubNavigationEntryModel): string {
        const subNavigationEntryHtml: string[] = [];
        const className: string[] = ["smb-sub-navigation-entry"];

        if (subNavigationEntry.isActive) {
            className.push("smb-is-active");
        }

        if (subNavigationEntry.link) {
            if (subNavigationEntry.isExternalLink) {
                subNavigationEntryHtml.push(`<a href="${subNavigationEntry.link}" rel="noopener noreferrer" target="_blank">`);
            }
            else {
                subNavigationEntryHtml.push(`<a href="${subNavigationEntry.link}">`);
            }
        }

        subNavigationEntryHtml.push(`<div class="${className.join(" ")}">${subNavigationEntry.text}</div>`);

        if (subNavigationEntry.link) {
            subNavigationEntryHtml.push(`</a>`);
        }

        return subNavigationEntryHtml.join("");
    }
}