import { SvgService } from "./Svg-Service";

export class FooterHtmlService {
    public static getFooterHtml(): string {
        const menuHtml: string[] = [];

        menuHtml.push(this.createFooterTopContainer());
        menuHtml.push(this.createFooterBottomContainer());

        return menuHtml.join("");
    }

    private static createFooterBottomContainer(): string {
        return `<div class="smb-bottom-container">
                    2021 | sendmeback GmbH. All rights reserved.
                </div>`;
    }

    private static createFooterTopContainer(): string {
        const topContainerHtml: string[] = [];

        topContainerHtml.push(`<div class="smb-top-container">`);
        topContainerHtml.push(`<div class="smb-main-container">`);

        topContainerHtml.push(this.createTopLeftContainer());
        topContainerHtml.push(this.createTopMiddleContainer());
        topContainerHtml.push(this.createTopRightContainer());

        topContainerHtml.push(`</div>`);
        topContainerHtml.push(`</div>`);

        return topContainerHtml.join("");
    }

    private static createLogoHtml(): string {
        return `<div class="smb-logo">
                    <img src="/Src/Images/Logos/smb-logo-landscape-dark.png" height="80" width="256" alt="Sendmeback-Logo">
                </div>`;
    }

    private static createSocialMediaContainer(): string {
        const socialMediaContainerHtml: string[] = [];

        socialMediaContainerHtml.push(`<div class="smb-social-media-container">`);

        socialMediaContainerHtml.push(`<div class="smb-social-media-facebook">`);
        socialMediaContainerHtml.push(`<a href="https://www.facebook.com/sendmeback.bird/" rel="noopener noreferrer" target="_blank">`);
        socialMediaContainerHtml.push(SvgService.getSvg("smb-icon-facebook", true));
        socialMediaContainerHtml.push(`</a>`);
        socialMediaContainerHtml.push(`</div>`);

        socialMediaContainerHtml.push(`<div class="smb-social-media-instagram">`);
        socialMediaContainerHtml.push(`<a href="https://www.instagram.com/sendmeback.bird/" rel="noopener noreferrer" target="_blank">`);
        socialMediaContainerHtml.push(SvgService.getSvg("smb-icon-instagram", true));
        socialMediaContainerHtml.push(`</a>`);
        socialMediaContainerHtml.push(`</div>`);

        socialMediaContainerHtml.push(`<div class="smb-social-media-tiktok">`);
        socialMediaContainerHtml.push(`<a href="https://www.tiktok.com/@sendmeback.bird?lang=de-DE" rel="noopener noreferrer" target="_blank">`);
        socialMediaContainerHtml.push(SvgService.getSvg("smb-icon-tiktok", true));
        socialMediaContainerHtml.push(`</a>`);
        socialMediaContainerHtml.push(`</div>`);

        socialMediaContainerHtml.push(`<div class="smb-social-media-youtube">`);
        socialMediaContainerHtml.push(`<a href="https://www.youtube.com/channel/UC00su50WeqJN7qptw0gamAg" rel="noopener noreferrer" target="_blank">`);
        socialMediaContainerHtml.push(SvgService.getSvg("smb-icon-youtube", true));
        socialMediaContainerHtml.push(`</a>`);
        socialMediaContainerHtml.push(`</div>`);

        socialMediaContainerHtml.push(`</div>`);

        return socialMediaContainerHtml.join("");
    }

    private static createTopLeftContainer(): string {
        const topLeftContainerHtml: string[] = [];

        topLeftContainerHtml.push(`<div class="smb-left-conatiner">`);
        topLeftContainerHtml.push(this.createLogoHtml());
        topLeftContainerHtml.push(this.createSocialMediaContainer());
        topLeftContainerHtml.push(`</div>`);

        return topLeftContainerHtml.join("");
    }

    private static createTopMiddleContainer(): string {
        return `<div class="smb-middle-conatiner">
                    <div class="smb-title">INFORMATIONEN</div>
                    <a href="/Impressum/"><div class="smb-footer-menu-entry">Impressum</div></a>
                    <a href="/Datenschutz/"><div class="smb-footer-menu-entry">Datenschutz</div></a>
                    <a href="/agb/"><div class="smb-footer-menu-entry">AGB's</div></a>
                </div>`;
    }

    private static createTopRightContainer(): string {
        return `<div class="smb-right-conatiner">
                    <div class="smb-title">ZAHLUNGSMITTEL</div>
                    <div class="smb-footer-pay-entry">PayPal</div>
                    <div class="smb-footer-pay-entry">Sofortüberweisung</div>
                    <div class="smb-footer-pay-entry">Visa/Mastercard</div>
                    <div class="smb-footer-pay-entry">Auf Rechnung</div>
                    <div class="smb-footer-pay-entry">Vorkasse</div>
                </div>`;
    }
}