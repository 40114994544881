import {
    MainNavigationEntryModel,
    MainNavigationModel,
    NavigationModel,
    SubNavigationEntryModel,
    SubNavigationModel,
} from "../Models/MenuModel";

export class MenuModelService {
    public static getMenu(locationPath: string): NavigationModel {
        const mainNavigation: MainNavigationModel = this.getMainNavigation();
        const subNavigation: SubNavigationModel = this.getSubNavigation();

        const navigation: NavigationModel =
            this.getNavigationWithActiveEntries(mainNavigation, subNavigation, locationPath);

        return navigation;
    }

    private static getMainNavigation(): MainNavigationModel {
        const leftNavigationEntries: MainNavigationEntryModel[] = [
            {
                category: "products",
                isAccount: false,
                isActive: false,
                isExternalLink: false,
                isFaq: false,
                isFound: false,
                isLost: false,
                link: "",
                text: "Produkte",
            },
            {
                category: "services",
                isAccount: false,
                isActive: false,
                isExternalLink: false,
                isFaq: false,
                isFound: false,
                isLost: false,
                link: "",
                text: "Services",
            },
            {
                category: "company",
                isAccount: false,
                isActive: false,
                isExternalLink: false,
                isFaq: false,
                isFound: false,
                isLost: false,
                link: "",
                text: "Unternehmen",
            },
            {
                category: "news",
                isAccount: false,
                isActive: false,
                isExternalLink: false,
                isFaq: false,
                isFound: false,
                isLost: false,
                link: "",
                text: "Aktuelles",
            },
            {
                category: "faq",
                isAccount: false,
                isActive: false,
                isExternalLink: false,
                isFaq: true,
                isFound: false,
                isLost: false,
                link: "/FAQ/",
                text: "FAQ",
            },
        ];
        const rightNavigationEntries: MainNavigationEntryModel[] = [
            {
                category: "",
                isAccount: false,
                isActive: false,
                isExternalLink: true,
                isFaq: false,
                isFound: false,
                isLost: true,
                link: "https://account.sendmeback.de/products",
                text: "Verlust melden",
            },
            {
                category: "",
                isAccount: false,
                isActive: false,
                isExternalLink: true,
                isFaq: false,
                isFound: true,
                isLost: false,
                link: "https://account.sendmeback.de/report-find",
                text: "Fund melden",
            },
            {
                category: "account",
                isAccount: true,
                isActive: false,
                isExternalLink: false,
                isFaq: false,
                isFound: false,
                isLost: false,
                link: "",
                text: "Mein Account",
            },
        ];

        return {
            leftNavigationEntries,
            logoURL: "/Src/Images/Logos/smb-logo-without-text-dark.png",
            mobilelogoURL: "/Src/Images/Logos/smb-logo-landscape-dark.png",
            rightNavigationEntries,
        };
    }

    private static getNavigationWithActiveEntries(
        mainNavigation: MainNavigationModel,
        subNavigation: SubNavigationModel,
        locationPath: string,
    ): NavigationModel {
        let activeSubCategory: string = "";

        const navigation: NavigationModel = {
            mainNavigation: {
                ...mainNavigation,
                leftNavigationEntries: mainNavigation.leftNavigationEntries.map(
                    (leftNavigationEntry: MainNavigationEntryModel) => {
                        if (leftNavigationEntry.category === activeSubCategory ||
                            leftNavigationEntry.link === locationPath
                        ) {
                            return {
                                ...leftNavigationEntry,
                                isActive: true,
                            };
                        }
                        else {
                            return {
                                ...leftNavigationEntry,
                            };
                        }
                    }),
            },
            subNavigation: {
                subNavigationEntries: subNavigation.subNavigationEntries.map(
                    (subNavigationEntry: SubNavigationEntryModel) => {
                        if (subNavigationEntry.link === locationPath) {
                            activeSubCategory = subNavigationEntry.category;

                            return {
                                ...subNavigationEntry,
                                isActive: true,
                            };
                        }
                        else {
                            return {
                                ...subNavigationEntry,
                            };
                        }
                    }),
            },
        };

        return navigation;
    }

    private static getSubNavigation(): SubNavigationModel {
        const subNavigationEntries: SubNavigationEntryModel[] = [
            {
                category: "products",
                isActive: false,
                isExternalLink: false,
                link: "/Produkte/ID-Tag/",
                text: "ID-TAG",
            },
            {
                category: "products",
                isActive: false,
                isExternalLink: false,
                link: "/Produkte/Keykeeper/",
                text: "KEYKEEPER",
            },
            {
                category: "services",
                isActive: false,
                isExternalLink: false,
                link: "/Services/Be-On/",
                text: "BE.ON",
            },
            {
                category: "services",
                isActive: false,
                isExternalLink: false,
                link: "/Services/Be-In/",
                text: "BE.IN",
            },
            {
                category: "services",
                isActive: false,
                isExternalLink: false,
                link: "/Services/Beprotect/",
                text: "BE.PROTECT",
            },
            {
                category: "company",
                isActive: false,
                isExternalLink: false,
                link: "/Unternehmen/About/",
                text: "Über Uns",
            },
            {
                category: "company",
                isActive: false,
                isExternalLink: false,
                link: "/Unternehmen/Nachhaltigkeit/",
                text: "Nachhaltigkeit",
            },
            // {
            //    category: "company",
            //    text: "KIDSFOUNDATION®",
            //    link: "/Unternehmen/Kidsfoundation/",
            //    isExternalLink: false,
            //    isActive: false,
            // },
            // {
            //    category: "company",
            //    text: "Kontakt",
            //    link: "/Unternehmen/Kontakt/",
            //    isExternalLink: false,
            //    isActive: false,
            // },
            // {
            //    category: "company",
            //    text: "Partner",
            //    link: "/Unternehmen/Partner/",
            //    isExternalLink: false,
            //    isActive: false,
            // },
            // {
            //    category: "company",
            //    text: "Shops",
            //    link: "/Unternehmen/Shops/",
            //    isExternalLink: false,
            //    isActive: false,
            // },
            {
                category: "news",
                isActive: false,
                isExternalLink: false,
                link: "/Aktuelles/Blog/",
                text: "Blog",
            },
            {
                category: "news",
                isActive: false,
                isExternalLink: false,
                link: "/Aktuelles/Jobs/",
                text: "Jobs",
            },
            // {
            //    category: "news",
            //    text: "Aktionen",
            //    link: "/Aktuelles/Aktionen/",
            //    isExternalLink: false,
            //    isActive: false,
            // },
            {
                category: "account",
                isActive: false,
                isExternalLink: true,
                link: "https://account.sendmeback.de/register",
                text: "Registrieren",
            },
            {
                category: "account",
                isActive: false,
                isExternalLink: true,
                link: "https://account.sendmeback.de/login",
                text: "Anmelden",
            },

        ];

        return {
            subNavigationEntries,
        };
    }
}