import $ from "jquery";
import { NavigationModel } from "../Models/MenuModel";
import { MenuHtmlService } from "../Services/Menu-Html-Service";
import { MenuMobileHtmlService } from "../Services/Menu-Mobile-Html-Service";
import { MenuModelService } from "../Services/Menu-Model-Service";

import "./Menu.less";

export class Menu {
    private activeCategory: string;
    private readonly navigationModel: NavigationModel;

    constructor() {
        const menuEntryPath: string = decodeURI(window.location.pathname);
        const isStartpage: boolean = $(".smb-page").hasClass("smb-startseite");

        this.navigationModel = MenuModelService.getMenu(menuEntryPath);
        this.activeCategory = "";

        const menuHtml: string[] = [];
        menuHtml.push(MenuHtmlService.getMenuHtml(this.navigationModel));
        menuHtml.push(MenuMobileHtmlService.getMobileMenuHtml(this.navigationModel, isStartpage));

        this.setContent(menuHtml.join(""));
        this.bindEvents();
    }

    public onRezise(): void {
        $(".smb-header").toggleClass("smb-is-open", false);
    }

    public setBackground(isTransparent: boolean): void {
        $(".smb-header").toggleClass("smb-is-start", isTransparent);
    }

    private bindDesktopEvents(): void {
        $(".smb-menu").on("mouseenter", ".smb-main-navigation-entry", (event: JQuery.TriggeredEvent) => {
            event.stopPropagation();

            const currentElement: JQuery<Element> = $(event.currentTarget);
            const isLost: boolean = currentElement.hasClass("smb-is-lost");
            const isFound: boolean = currentElement.hasClass("smb-is-found");
            const isFAQ: boolean = currentElement.hasClass("smb-is-faq");

            if (isLost || isFound || isFAQ) {
                if (this.activeCategory) {
                    this.hideSubHeaderEntries(this.activeCategory);

                    return;
                }

                return;
            }

            const category: string = currentElement.attr("data-category") as string;

            if (this.activeCategory) {
                this.hideSubHeaderEntries(this.activeCategory);
            }

            this.activeCategory = category;
            this.showSubHeaderEntries(category);
        });

        $(".smb-menu").on("mouseleave ", (event: JQuery.TriggeredEvent) => {
            const currentElement: JQuery<Element> = $(event.currentTarget);
            const category: string = currentElement.attr("data-category") as string;

            this.hideSubHeaderEntries(category);
        });
    }

    private bindEvents(): void {
        this.bindDesktopEvents();
        this.bindMobileEvents();
    }

    private bindMobileEvents(): void {
        $(".smb-menu").on("click", ".smb-menu-button", (event: JQuery.TriggeredEvent) => {
            const isOpened: boolean = $(".smb-header").hasClass("smb-is-open");

            if (isOpened) {
                $(".smb-header").toggleClass("smb-is-open");
                $(".smb-mobile-sub-navigation-main-entry").toggleClass("smb-is-opened", false);
                $(".smb-mobile-sub-navigation-sub-entries").toggleClass("smb-hide", true);
                $(".smb-mobile-sub-navigation-sub-entries.smb-is-active").toggleClass("smb-hide", false);
            } else {
                $(".smb-header").toggleClass("smb-is-open");
            }
        });

        $(".smb-menu").on("click", ".smb-mobile-sub-navigation-main-entry", (event: JQuery.TriggeredEvent) => {
            const currentElement: JQuery<Element> = $(event.currentTarget);
            const mainCategory: string = currentElement.attr("data-category") as string;

            $(".smb-mobile-sub-navigation-main-entry").toggleClass("smb-is-opened", false);
            $(".smb-mobile-sub-navigation-sub-entries").toggleClass("smb-hide", true);

            currentElement.toggleClass("smb-is-opened", true);

            $(".smb-mobile-sub-navigation-top-entries")
                .find(`[data-paranet-category="${mainCategory}"]`)
                .toggleClass("smb-hide");

            $(".smb-mobile-sub-navigation-sub-entries.smb-is-active").toggleClass("smb-hide", false);
        });
    }

    private hideSubHeaderEntries(category: string) {
        const selector: string = `[data-parent-category="${category}"]`;

        if (!$(".smb-page").hasClass("smb-mobile")) {
            $(".smb-header").toggleClass("smb-is-open", false);
        }

        $(".smb-sub-navigation").toggleClass("smb-hide", true);
        $(selector).toggleClass("smb-hide", true);
        $(".smb-header-shadow").toggleClass("smb-hide", true);
    }

    private setContent(menuHtml: string): void {
        $(".smb-menu").html(menuHtml);
    }

    private showSubHeaderEntries(category: string) {
        const selector: string = `.smb-sub-navigation [data-parent-category="${category}"]`;

        $(".smb-header").toggleClass("smb-is-open", true);
        $(".smb-sub-navigation").toggleClass("smb-hide", false);
        $(selector).toggleClass("smb-hide", false);
        $(".smb-header-shadow").toggleClass("smb-hide", false);
    }
}