import $ from "jquery";
import { Footer } from "./Footer/Footer";
import { Menu } from "./Menu/Menu";

import "./styles.less";

$.expr[":"].contains = $.expr.createPseudo((arg: any): any => {
    return (elem: any): any => {
        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});

export class App {
    public menu: Menu;
    private readonly isStartseite: boolean;

    constructor() {
        this.menu = new Menu();
        new Footer();

        this.setMobileClass();
        this.bindEvents();
        this.isStartseite = $(".smb-page").hasClass("smb-startseite");
    }

    private bindEvents(): void {
        $(window).on("resize", (bla: JQuery.TriggeredEvent): void => {
            this.setMobileClass();
            this.menu.onRezise();
        });

        $(".smb-content").on("scroll", (event: JQuery.TriggeredEvent): void => {
            const currentElement: JQuery<Element> = $(event.currentTarget);
            const currentScrollTop: number = currentElement.scrollTop() as number;
            const isStartseiteVideoPlaying: boolean = $(".smb-header").hasClass("smb-video-is-playing");

            if (currentScrollTop === 0 && this.isStartseite && !isStartseiteVideoPlaying) {
                this.menu.setBackground(true);
            }
            else {
                this.menu.setBackground(false);
            }
        });
    }

    private setMobileClass(): void {
        const windowWitdh: number = $(window).width() as number;
        //const windowHeight: number = $(window).height() as number;
        const isMobile: boolean = windowWitdh <= 1024;

        $(".smb-page").toggleClass("smb-mobile", isMobile);
    }
}